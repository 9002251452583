import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateRandomSeed } from '../helpers/seedGenerator';
import axiosInstance from '../helpers/axios';
import Tracking from '../services/TrackingService';
import GenerationModelValues from '../data/models';
import contentStyleTextHelpers from '../data/contentStyleTextHelpers';
import {
  convertImageFileToBase64,
  convertImageToBase64,
  getImageSize,
} from '../helpers/convertUrlToBase64';
import authAxiosInstance from '../helpers/auth-axios';
import DownloadImage from '../services/ImageDownloader';
import { UPSCALE_RESOLUTIONS } from '../data/dimensions';
import { setBackgroundImage } from './imageEditorReducer';
import { setUserTokens, verifyUserToken } from './userReducer';
import { resizeBase64 } from '../components/editor/resizers';
import {
  CONFIG_PROPS,
  STYLE_SETTINGS,
  generate_style_config,
  randomize_config_item,
} from '../components/input/style-config/constants';
import { SDColors } from '../components/redesign/constants';
import { removeEmpty } from 'react-admin';
import { getColorName } from '../components/input/V4ColorSelector';
import { captureMonitoringEvent, recordGenerationStartTime } from '../helpers/socketHandler';

const selectEndpoint = (state) => {
  if (!state.form.payload.inputImage && state.form.method === 'redesign')
    return 'gen';
  return state.form.method;
};

const fieldsToExclude = {
  gen: [
    'inputImage',
    'denoisingStrength',
    'guidenceStrength',
    'edgeThreshold',
    'redesignMethod',
    'image_as_control_image',
    'controlGuidanceStart',
    'injectColors',
    'shouldInjectColors',
    'controlnetMode',
  ],
  sdxl: [],
  'sdxl-3d': [],
  redesign: [
    'controlGuidanceStart',
    'injectColors',
    'shouldInjectColors',
    'controlnetMode',
  ],
  ddd: [
    'controlGuidanceStart',
    'injectColors',
    'shouldInjectColors',
    'controlnetMode',
  ],
  upscale: [
    'redesignMethod',
    'sampler',
    'promptHelpers',
    'promptTextHelper',
    'selectedModel',
    'prompt',
    'negativePrompt',
    'controlGuidanceStart',
    'injectColors',
    'shouldInjectColors',
    'controlnetMode',
  ],
  'image-masks': [
    'redesignMethod',
    'sampler',
    'promptHelpers',
    'promptTextHelper',
    'selectedModel',
    'prompt',
    'negativePrompt',
    'controlGuidanceStart',
    'injectColors',
    'shouldInjectColors',
    'controlnetMode',
  ],

  'v3/2d': [],
  'v3/bolt': [],
  'v3/waterfall': [],

  'v4/bolt': ['loras'],
  'v4/style-transfer': ['loras'],
  'v4/waterfall': ['loras'],
  'v4/xl': ['loras'],
};

const removeAfterDevMode = ['refinerInfluence'];

const generatePayload = (state, endpoint) => {
  let excludedFields = [...fieldsToExclude[endpoint]];
  if (!state.form.payload.image_as_control_image)
    excludedFields = [...excludedFields, 'denoisingStrength'];
  const obj = Object.entries(state.form.payload).reduce(
    (newPayload, [key, value]) => ({
      ...newPayload,
      ...(excludedFields.indexOf(key) < 0 && {
        [key]: value,
      }),
    }),
    {
      user: state.userSlice.user._id,
    }
  );

	// Remove fields from the style config that are not included in the settings
	const currentStyle = obj.style ?? obj.contentStyle;
	const styleSettings = CONFIG_PROPS[state.form.method][currentStyle] ?? {};

	obj.styleConfig = Object.entries(obj.styleConfig).reduce(
		(prev, [key, val]) => ({
			...prev,
			...(styleSettings[key] && { [key] : val }),
		}),
		{}
	);

	return obj
};

export const generateImages = createAsyncThunk(
  'form/generation',
  async (_, thunkApi) => {
    const endpoint = selectEndpoint(thunkApi.getState());

    const imageSize = Number(
      thunkApi.getState().form.payload.dimension.split(' ')[0]
    );
    const resizedInputImage = thunkApi.getState().form.payload.inputImage
      ? (
          await resizeBase64(
            thunkApi.getState().form.payload.inputImage,
            imageSize,
            imageSize
          )
        ).image
      : null;

    const payload = generatePayload(thunkApi.getState(), endpoint);
    try {
      await thunkApi.dispatch(verifyUserToken()).unwrap();
      const resp = await axiosInstance.post(
        `/api/${endpoint}`,
        {
          ...payload,
          inputImage: resizedInputImage,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const response = resp.data;

			if (response.isAsync) {
				recordGenerationStartTime(
					response.generationId,
					endpoint,
				)
			}

      /*
      Tracking.send(
        "generation",
        {
          // track redesign 2d generation ( controlNet / standard )
          user: thunkApi.getState().userSlice.user,
          value: response.info.a1.request.batch_size || 1,
          endpoint: endpoint,
          method: "standard", // standard / 3D / cNet-2D / cNet-3D
          rootMethod: "txtToImg", // txtToImg / ImgToImg
          lodi_request: response.info.lodi.request,
          a1_request: response.info.a1.request,
          a1_response: response.info.a1.response,
          new_generations: response.new_generations,
          request_body: payload,
        },
        {
          // custom data
        }
      );
			*/

      if (response.user)
        thunkApi.dispatch(
          setUserTokens({ tokens: response.user.remainingCredits })
        );

      return response;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const generateImageMasks = createAsyncThunk(
  'form/generate-masks',
  async (_, thunkApi) => {
    const endpoint = 'image-masks';

    const resizedInputImage = thunkApi.getState().form.payload.inputImage
      ? (await resizeBase64(thunkApi.getState().form.payload.inputImage)).image
      : null;

    const payload = generatePayload(thunkApi.getState(), endpoint);
    try {
      const resp = await axiosInstance.post(`/api/${endpoint}`, {
        ...payload,
        inputImage: resizedInputImage,
      });

      const response = resp.data;

      return { edgeThreshold: payload.edgeThreshold, response };
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const upscaleImage = createAsyncThunk(
  'form/upscale-image',
  async (data, thunkApi) => {
    const endpoint = 'upscale';
    const payload = generatePayload(thunkApi.getState(), endpoint);

    try {
      const base64 = await convertImageToBase64(data.imageUrl, null, null); // Use image's width/height

      // const size = await getImageSize(base64);

      // const dimension = `${size.width * 2} x ${size.height * 2}`;

      const dimension = UPSCALE_RESOLUTIONS[data.dimension || data.dimensions];

      const resp = await axiosInstance.post(`/api/${endpoint}`, {
        ...payload,
        dimension,
        inputImage: base64,
        method_size: (data.dimension || data.dimensions).split(' ')[0],
      });

      const response = resp.data;

      const link = document.createElement('a');
      link.href = `data:image/png;base64,${response.images[0]}`;
      link.download = `LoDi-${data.prompt}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      thunkApi.dispatch(
        setUserTokens({ tokens: response.user.remainingCredits })
      );

      Tracking.send(
        'upscale',
        {
          user: thunkApi.getState().userSlice.user,
          baseDimentsions: data.dimension || data.dimensions,
          targetDimensions: dimension,
          image: data.imageUrl,
          generationId: data.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return response;
    } catch (e) {
      console.log(e);
      return thunkApi.rejectWithValue({ error: e.response?.data, e });
    }
  }
);

export const upscaleImageTool = createAsyncThunk(
  'form/upscale-image-tool',
  async (payload, thunkApi) => {
    const { endpoint = 'v3/upscale', ...endpointPayload } = payload;
    try {
      const resp = await axiosInstance.post(`/api/${endpoint}`, {
        ...endpointPayload,
        user: thunkApi.getState().userSlice.user._id,
      });

      const response = resp.data;

      thunkApi.dispatch(
        setUserTokens({ tokens: response.user.remainingCredits })
      );

      Tracking.send(
        'upscale',
        {
          user: thunkApi.getState().userSlice.user,
          image: payload.imageUrl,
          generationId: payload.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return response;
    } catch (e) {
      console.log(e);
			captureMonitoringEvent({
				name: 'generation-failed',
				method: `/api/${endpoint}`,
			})
      return thunkApi.rejectWithValue({ error: e.response?.data, e });
    }
  }
);

export const setImageAsInspiration = createAsyncThunk(
  'form/inspiration',
  async (payload, thunkApi) => {
    const base64 = await convertImageToBase64(payload.imageUrl, 1024, 1024);

    thunkApi.dispatch(setBackgroundImage(base64));

    return {
      image: base64,
      method: payload.method,
    };
  }
);

export const loadPartialGenerationResult = createAsyncThunk(
  'form/load-partial-generation-result',
  async (payload, thunkApi) => {
    try {
      const response = await authAxiosInstance.get(
        `/api/generation/${payload.id}`
      );

      return {
        ind: payload.ind,
        ...response.data,
      };
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const loadGenerationById = createAsyncThunk(
  'form/load-generation-by-id',
  async (payload, thunkApi) => {
    try {
      const response = await authAxiosInstance.get(
        `/api/generation/by-generation/${payload.generationId}`
      );

      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const loadGenerationHistory = createAsyncThunk(
  'form/load-history',
  async (payload, thunkApi) => {
    try {
      const userId = thunkApi.getState().userSlice.user._id;
      const startFrom = thunkApi.getState().form.generations.length;
      const response = await authAxiosInstance.get(
        `/api/generation/by-user?userId=${userId}&range=[${startFrom},${
          startFrom + 15
        }]`
      );

      return response.data;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const bookmarkImage = createAsyncThunk(
  'form/bookmark',
  async (payload, thunkApi) => {
    try {
      const { data } = await authAxiosInstance.put(
        `/api/generation/${payload._id}`,
        {
          bookmarked: payload.bookmarked,
        }
      );

      Tracking.send('bookmark', {
        user: thunkApi.getState().userSlice.user,
        image: data,
      });

      return data;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const deleteImage = createAsyncThunk(
  'form/delete',
  async (payload, thunkApi) => {
    try {
      const response = await authAxiosInstance.delete(
        `/api/generation/${payload}`
      );

      return payload;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const bgRemove = createAsyncThunk(
  'form/remobeBg',
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(`/api/remove-bg`, {
        user: thunkApi.getState().userSlice.user._id,
        imageUrl: payload.imageUrl,
      });

      const link = document.createElement('a');
      link.href = `data:image/png;base64,${response.data.image}`;
      link.download = `LoDi-${payload.prompt}.png`;
      document.body.appendChild(link);
      link.click();
      setTimeout(document.body.removeChild(link), 1000);

      thunkApi.dispatch(
        setUserTokens({ tokens: response.data.user.remainingCredits })
      );

      Tracking.send(
        'remove-bbackground',
        {
          user: thunkApi.getState().userSlice.user,
          imageUrl: payload.imageUrl,
          generationId: payload.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return payload;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);
export const bgRemoveTool = createAsyncThunk(
  'form/remove-bg-tool',
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(`/api/remove-bg`, {
        user: thunkApi.getState().userSlice.user._id,
        inputImage: payload,
      });

      thunkApi.dispatch(
        setUserTokens({ tokens: response.data.user.remainingCredits })
      );

      Tracking.send(
        'remove-bbackground',
        {
          user: thunkApi.getState().userSlice.user,
          image: payload.imageUrl,
          generationId: payload.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return response;
    } catch (e) {
			captureMonitoringEvent({
				name: 'generation-failed',
				method: '/api/remove-bg'
			})
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const vectorizeImage = createAsyncThunk(
  'form/vectorize',
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(`/api/vectorize`, {
        user: thunkApi.getState().userSlice.user._id,
        imageUrl: payload.imageUrl,
      });

      const link = document.createElement('a');
      link.href = response.data.imageUrl;
      link.download = `LoDi-${payload.prompt}.svg`;
      document.body.appendChild(link);
      link.click();
      setTimeout(document.body.removeChild(link), 1000);

      thunkApi.dispatch(
        setUserTokens({ tokens: response.data.user.remainingCredits })
      );

      Tracking.send(
        'vectorize',
        {
          user: thunkApi.getState().userSlice.user,
          imageUrl: payload.imageUrl,
          generationId: payload.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return payload;
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const vectorizeImageTool = createAsyncThunk(
  'form/vectorize-tool',
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(`/api/vectorize`, {
        user: thunkApi.getState().userSlice.user._id,
        inputImage: payload,
      });

      thunkApi.dispatch(
        setUserTokens({ tokens: response.data.user.remainingCredits })
      );

      Tracking.send(
        'vectorize',
        {
          user: thunkApi.getState().userSlice.user,
          image: payload.imageUrl,
          generationId: payload.generationId,
          prompt: payload.prompt,
        },
        {}
      );

      return response;
    } catch (e) {
			captureMonitoringEvent({
				name: 'generation-failed',
				method: '/api/vectorize'
			})
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const loadSettings = createAsyncThunk(
  'form/load',
  async (payload, thunkApi) => {
    const mapPayloadFields = (obj) => {
      const fieldMap = [
        {
          name: 'steps',
          mappedTo: 'imageQuality',
        },
        {
          name: 'styles',
          mappedTo: 'promptHelpers',
        },
        {
          name: 'dimensions',
          mappedTo: 'dimension',
        },

        { name: 'denoisingStrength' },
        { name: 'edgeThreshold' },
        { name: 'prompt' },
        { name: 'negativePrompt' },
        { name: 'imageQuality' },

        { name: 'imageWeight' },
        { name: 'promptRigidness' },

        { name: 'promptHelpers' },
        {
          name: 'promptTextHelper',
          f: (v) => {
            return contentStyleTextHelpers.find(
              (item) => item.slug === v || item.label === v || item == v
            );
          },
        },

        { name: 'contentStyle' },
        { name: 'detailLevel' },

        { name: 'redesignMethod' },

        { name: 'sampler' },
        { name: 'dimension' },

        { name: 'seed' },

        { name: 'image_as_control_image' },

        { name: 'controlnetMode' },

        { name: 'scheduler' },

        { name: 'useRefiner' },
        { name: 'refinerInfluence' },
        { name: 'schedulerRefiner' },
        { name: 'strengthRefiner' },
        { name: 'denoising_start' },
        { name: 'denoising_end' },

        { name: 'controlGuidanceStart' },
        { name: 'controlGuidanceEnd' },

        { name: 'depthWeight' },
        { name: 'depthWeightGuidanceEnd' },

        { name: 'shouldInjectColors' },
        { name: 'injectColors' },

        { name: 'loras' },

        {
          name: 'styleConfig',
          f: (v) =>
            Object.entries(v).reduce(
              (prev, [key, val]) => ({
                ...prev,
                [key]: { 
									...val, 
									__randomized: false,
									colors: payload.method.startsWith('v4') ? (
										val?.colors.map((c) => typeof c === 'string' ? 
											{
												oldColor: c,
												name: getColorName(
													SDColors.find(x => x.id === c)?.hex,
													c,
												),
												color: SDColors.find(x => x.id === c)?.hex,
											}
											: c)
									) : val.colors,
								},
              }),
              {}
            ),
        },

				// V4
				{ name: 'imageStrength', },
				{ name: 'style', },

				// V4.0 Lightning
				{ name: 'multiCnSwitch', },
				{ name: 'depthSwitch', },
				{ name: 'cannySwitch', },

				{ name: 'useImageColors', },
				{ name: 'main_sampler_denoise_value', },

				// V4.0 Style Transfer
				{ name: 'useBackgroundSeparation', },
				{ name: 'backgroundSeparation', },
				{ name: 'useStyleStrength', },
				{ name: 'styleStrength', },

				// V4.0 Mulago
				{ name: 'cnSwitch', },

				// { name: 'styleImage', },

      ];

      return fieldMap.reduce((cur, item) => {
        if (obj[item.name] !== undefined && obj[item.name] !== null) {
          return {
            ...cur,
            [item.mappedTo || item.name]: (item.f || ((x) => x))(
              obj[item.name]
            ),
          };
        }
        return cur;
      }, {});
    };
    try {
      if (payload.inputImage && payload.inputImage !== '')
        await thunkApi
          .dispatch(
            setImageAsInspiration({ ...payload, imageUrl: payload.inputImage })
          )
          .unwrap();
      else {
        thunkApi.dispatch(updateInputImage(null));
        thunkApi.dispatch(setBackgroundImage(null));
      }

      if (payload.styleImage && payload.styleImage !== '')
        thunkApi
          .dispatch(
            updateStyleImageUrl(payload.styleImage)
          );
      else {
        thunkApi.dispatch(updateStyleImage(null));
      }

      return {
        payload: mapPayloadFields(payload),
        method: payload.method === 'gen' ? 'redesign' : payload.method,
      };
    } catch (e) {
      return thunkApi.rejectWithValue({ error: e.response.data });
    }
  }
);

export const submitFeedback = createAsyncThunk(
  'form/submit-feedback',
  async (payload, thunkApi) => {
    try {
      const response = await authAxiosInstance.put(
        `/api/generation/${payload.id}/feedback`,
        {
          feedback: payload.feedback,
        }
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return thunkApi.rejectWithValue({ error: e.response?.data, e });
    }
  }
);

// Speed mode
export const DEFAULT_SETTINGS_2D = {
  sampler: 'Euler a',
  dimension: '512 x 512',
  denoisingStrength: 0.86,
  contentStyle: 'pictorial',
  detailLevel: 0,
  redesignMethod: 'rough-edges',
  edgeThreshold: 32, // Edge detection
  image_as_control_image: true,
  imageWeight: 1.6, // Image influence
  imageQuality: 35,
  useRefiner: true,
  refinerInfluence: 0.0125, // Only on dev mode
};
export const DEFAULT_SETTINGS_3D = {
  sampler: 'Euler a',
  dimension: '768 x 768',
  denoisingStrength: 0.86,
  contentStyle: 'none',
  detailLevel: 0,
  redesignMethod: 'thin-outline',
  edgeThreshold: 4, // Edge detection
  image_as_control_image: true,
  imageWeight: 1.6, // Image influence
  imageQuality: 35,
  useRefiner: true,
  refinerInfluence: 0.0125, // Only on dev mode
};

// Quality mode
export const DEFAULT_SETTINGS_SDXL = {
  sampler: 'Euler a',
  dimension: '1024 x 1024',
  denoisingStrength: 0.99,
  contentStyle: 'pictorial',
  detailLevel: 0,
  redesignMethod: 'thin-outline',
  edgeThreshold: 32, // Edge detection
  image_as_control_image: false,
  imageWeight: 0.65, // Image influence
  imageQuality: 30,
  useRefiner: true,
  refinerInfluence: 0.4, // Only on dev mode
  controlGuidanceStart: 0,
  controlGuidanceEnd: 0.8,
  controlnetMode: 'details',
};
export const DEFAULT_SETTINGS_SDXL_3D = {
  sampler: 'Euler a',
  dimension: '1024 x 1024',
  denoisingStrength: 0.99,
  contentStyle: '3d',
  detailLevel: 0,
  redesignMethod: 'thin-outline',
  edgeThreshold: 32, // Edge detection
  image_as_control_image: false,
  imageWeight: 0.65, // Image influence
  imageQuality: 30,
  useRefiner: true,
  refinerInfluence: 0.2, // Only on dev mode
  controlGuidanceStart: 0,
  controlGuidanceEnd: 0.8,
  controlnetMode: 'depth',
};

export const DEFAULT_SETTINGS_V3_2D = {
  scheduler: 'Euler a',
  dimension: '1024 x 1024',
  denoisingStrength: 0.99,
  contentStyle: 'pictorial',
  detailLevel: 0,
  image_as_control_image: false,
  imageWeight: 0.65, // Image influence
  imageQuality: 24,
  useRefiner: true,
  refinerInfluence: 0.1, // Only on dev mode
  schedulerRefiner: 'Euler a',
  controlGuidanceStart: 0,
  controlGuidanceEnd: 0.8,
  controlnetMode: 'details',
  loras: [['logotpictorial-AC-vU3B-50.safetensors', 0.4, 0.3]],
  canny_low_threshold: null,
  canny_high_threshold: null,
  denoising_start: null,
  denoising_end: null,
  promptRigidness: 5.5,
};

export const SHARED_SETTINGS = {
	prompt: '',
	negativePrompt: '',
	inputImage: null,
	seed: generateRandomSeed(),
	imagesToGenerate: 4,
  dimension: '1024 x 1024',
}

export const DEFAULT_SETTINGS_V4_WATERFALL = {
	__reset_state: true,
  style: 'pictorial',
  useImageColors: true,
	imageStrength: 5,
};

export const DEFAULT_SETTINGS_V4_XL = {
	__reset_state: true,
  style: 'mascot',

	cnSwitch: 'On',
	imageStrength: 7,
	
  useImageColors: false,
	main_sampler_denoise_value: 5,
};
export const DEFAULT_SETTINGS_V4_BOLT = {
	__reset_state: true,
  style: 'stylized-cartoon',

	multiCnSwitch: 'On',
	depthSwitch: 'On',
	cannySwitch: 'On',
	imageStrength: 5,
	
  useImageColors: false,
	main_sampler_denoise_value: 3, // Image colors
};
export const DEFAULT_SETTINGS_V4_STYLE_TRANSFER = {
	...DEFAULT_SETTINGS_V4_BOLT,
  style: 'realistic',

	useBackgroundSeparation: true,
	backgroundSeparation: 4,

	useStyleStrength: true,
	styleStrength: 5,

	// Default settings
	depthSwitch: 'On',
	cannySwitch: 'On',
	imageStrength: 7,
};


export const DEFAULT_STYLE_MAPPINGS = {
  redesign: DEFAULT_SETTINGS_2D,
  ddd: DEFAULT_SETTINGS_3D,
  sdxl: DEFAULT_SETTINGS_SDXL,
  'sdxl-3d': DEFAULT_SETTINGS_SDXL_3D,
  'v3/2d': DEFAULT_SETTINGS_V3_2D,
  'v4/waterfall': DEFAULT_SETTINGS_V4_WATERFALL,
  'v4/xl': DEFAULT_SETTINGS_V4_XL,
  'v4/bolt': DEFAULT_SETTINGS_V4_BOLT,
  'v4/style-transfer': DEFAULT_SETTINGS_V4_STYLE_TRANSFER,
};

export const GENERATION_STATUS = {
  IDLE: 'idle',
  GENERATING: 'generating',
  FAILED: 'failed',
  SUCCEDED: 'succeded',
  IN_QUEUE: 'in_queue',
};

export const INITIAL_STATE = {
  payload: {
    guidanceStrength: 0.6 /* unused */,
    denoisingStrength: 0.99, // Creative freadom - Use image colors
    edgeThreshold: 32, // Edge detection
    prompt: '',
    negativePrompt: '',
    imageQuality: 30,

    imageWeight: 0.65, // Image influence - Use Image shape
    promptRigidness: 7, // Advanced
    selectedModel: {
      ...GenerationModelValues[0],
      smallThumbnail: undefined,
    } /* unused */,

    detailLevel: 0,

    promptHelpers: [],
    promptTextHelper: {
      ...contentStyleTextHelpers[0],
      smallThumbnail: undefined,
    },

    contentStyle: 'mascot',

    styleConfig: generate_style_config('v4/xl', 'mascot'),
    // styleConfig: generate_style_config('v4/xl', 'pictorial'),

    redesignMethod: 'thin-outline',

    sampler: 'Euler a',
    dimension: '1024 x 1024',

    imagesToGenerate: 4,

    seed: generateRandomSeed(),

    inputImage: null,

    shouldInjectColors: false,
    injectColors: [],

    image_as_control_image: false, // Use image colors

    useRefiner: true,
    refinerInfluence: 0.4, // Only on dev mode

    controlGuidanceStart: 0,
    controlGuidanceEnd: 0.8,

    overridePromptInjection: false,

    useMaxWorkers: false,

    enableMultipleControlnets: false,
    depthWeight: 0,
    depthWeightGuidanceEnd: 0,

    runpodOverridePod: '',

    controlnetMode: 'details',
    // ...DEFAULT_SETTINGS_V3_2D,
    ...DEFAULT_SETTINGS_V4_XL,
  },
  // method: 'v3/2d',
  method: 'v4/xl',
  __autogenerated_seed: true,
  __free_seed_after_generation: false,

  __dev_mode: false,

  isLoading: false,
  generationStatus: GENERATION_STATUS.IDLE,
  showPlaceholders: true,

  generations: [],
  selectedHistoryGeneration: null,
  hasMoreGenerationsToLoad: true,
  isLoadingGenerations: false,
  lastGenerationId: null,
	activeHistoryGeneration: null,

  inspirationLoading: false,

  showImageViewer: false,
  viewingImageUrl: null,

  isGeneratingMasks: false,
  generatedMasks: {},

  isUpscaling: false,
  isUpscalingTool: false,
  isVectorizingTool: false,
  isVectorizing: false,
  isRemovingBg: false,
  isRemovingBgTool: false,

  __force_update_prompt: null,

  showOutOfCreditModal: false,
  outOfCreditTitle: "You don't have enough credits!",
  outOfCreditSubtitle: 'Upgrade for more credits and features',
  outOfCreditImage: null,

  showStyleSelector: false,
  showStyleSelectorTutorial: false,
  showAdvancedSettingPopup: false,

  showPromptTutorial: false,

  configTutorial: {
    title: '',
    body: '',
    style: null,
  },

  showQueueForRequest: false,
  queueStatus: {
    total: 0,
    position: 0,
  },

  __runpod_payload: '',
  __runpod_error: '',

	devModeFields: [],
};

export const formSlice = createSlice({
  name: 'formSlice',
  initialState: INITIAL_STATE,
  reducers: {
    reset(state) {
      return INITIAL_STATE;
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    partialUpdateStatePayload(state, action) {
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    },
    updateAfterUpscaleImage(state, action) {
      state.afterUpscaleImage = null;
    },
    updateAfterBgRemoveImage(state, action) {
      state.afterBgRemoveImage = null;
    },
    updateAfterVectorizeImage(state, action) {
      state.afterVectorizeImage = null;
    },
    updateImageAsControlImage(state, action) {
      state.payload.image_as_control_image = action.payload;
    },
    updateGuidenceStregth(state, action) {
      state.payload.guidanceStrength = action.payload;
    },
    updateImageWeight(state, action) {
      state.payload.imageWeight = action.payload;
    },
    updatePromptRigidness(state, action) {
      state.payload.promptRigidness = action.payload;
    },
    updateEdgeThreshold(state, action) {
      state.payload.edgeThreshold = action.payload;
    },
    updatePrompt(state, action) {
      state.payload.prompt = action.payload;
    },
    updateNegativePrompt(state, action) {
      state.payload.negativePrompt = action.payload;
    },
    updateMethod(state, action) {
      if (state.method !== action.payload) {
				if (action.payload.startsWith('v4') && !state.method.startsWith('v4')) {
					state.payload = {
						...SHARED_SETTINGS,
						...DEFAULT_STYLE_MAPPINGS[action.payload],
					};
				} else if (action.payload.startsWith('v4') && state.method.startsWith('v4')) {
					state.payload = {
						...state.payload,
						...DEFAULT_STYLE_MAPPINGS[action.payload],
						style: state.payload.style,
					};
				} else {
					state.payload = {
						...INITIAL_STATE.payload,
						...state.payload,
						...DEFAULT_STYLE_MAPPINGS[action.payload],
					};
				}
      }

      state.method = action.payload;
    },
    updateRedesignMethod(state, action) {
      state.payload.redesignMethod = action.payload;
    },
    updateImageQuality(state, action) {
      state.payload.imageQuality = action.payload;
    },
    updateDimensions(state, action) {
      state.payload.dimension = action.payload;
    },
    updateSampler(state, action) {
      state.payload.sampler = action.payload;
    },
    updateScheduler(state, action) {
      state.payload.scheduler = action.payload;
    },
    updateImagesToGenerate(state, action) {
      state.payload.imagesToGenerate = action.payload;
    },

    updateSeed(state, action) {
      state.payload.seed = action.payload;
      state.__autogenerated_seed = false;
    },
    freezeSeed(state, action) {
      state.__autogenerated_seed = false;
    },
    unfreezeSeed(state, action) {
      state.__autogenerated_seed = true;
    },
    regenerateSeed(state, action) {
      state.payload.seed = generateRandomSeed();
    },

    updateInputImage(state, action) {
      state.payload.inputImage = action.payload;
      state.generatedMasks = {};
    },

    updatePromptHelpers(state, action) {
      state.payload.promptHelpers = action.payload;
    },
    appendPromptHelper(state, action) {
      state.payload.promptHelpers = [
        ...state.payload.promptHelpers,
        action.payload,
      ];
    },
    removePromptHelper(state, action) {
      state.payload.promptHelpers = state.payload.promptHelpers.filter(
        (item) => item !== action.payload
      );
    },
    togglePromptHelper(state, action) {
      if (state.payload.promptHelpers.indexOf(action.payload) < 0) {
        state.payload.promptHelpers = [
          ...state.payload.promptHelpers,
          action.payload,
        ];
      } else {
        state.payload.promptHelpers = state.payload.promptHelpers.filter(
          (item) => item !== action.payload
        );
      }
    },

    updatePromptTextHelper(state, action) {
      state.payload.promptTextHelper = action.payload;
    },

    showImageModal(state, action) {
      state.showImageViewer = true;
      state.viewingImageUrl = action.payload;
    },
    closeImageModal(state, action) {
      state.showImageViewer = false;
      state.viewingImageUrl = null;
    },
    clearPromptForceUpdate(state, action) {
      state.__force_update_prompt = null;
    },
    updatePromptForceUpdate(state, action) {
      state.__force_update_prompt = action.payload;
    },

    updateShowOutOfCreditModal(state, action) {
      if (typeof action.payload === 'boolean') {
        state.showOutOfCreditModal = action.payload;
        state.outOfCreditTitle = INITIAL_STATE.outOfCreditTitle;
        state.outOfCreditSubtitle = INITIAL_STATE.outOfCreditSubtitle;
        state.outOfCreditImage = INITIAL_STATE.outOfCreditImage;
      } else {
        state.showOutOfCreditModal = action.payload.open;
        state.outOfCreditTitle = action.payload.title;
        state.outOfCreditSubtitle = action.payload.subtitle;
        state.outOfCreditImage = action.payload.image;
      }
    },
    updateOutOfCreditText(state, action) {
      if (action.payload) {
        state.outOfCreditTitle = action.payload.title;
        state.outOfCreditSubtitle = action.payload.subtitle;
        state.outOfCreditImage = action.payload.image;
      } else {
        state.outOfCreditTitle = INITIAL_STATE.outOfCreditTitle;
        state.outOfCreditSubtitle = INITIAL_STATE.outOfCreditSubtitle;
        state.outOfCreditImage = INITIAL_STATE.outOfCreditImage;
      }
    },

    updateContentStyle(state, action) {
      state.payload.contentStyle = action.payload;
    },

    updateShouldInjectColros(state, action) {
      state.payload.shouldInjectColors = action.payload;
    },
    updateInjectColorsAt(state, action) {
      state.payload.injectColors[action.payload.index] = action.payload.value;
    },

    updateDetailLevel(state, action) {
      state.payload.detailLevel = action.payload;
    },

    updateShowStyleSelector(state, action) {
      state.showStyleSelector = action.payload;
    },
    updateShowStyleSelectorTutorial(state, action) {
      state.showStyleSelectorTutorial = action.payload;
    },

    updateShowAdvancedSettingPopup(state, action) {
      state.showAdvancedSettingPopup = action.payload;
    },

    updateConfigTutorial(state, action) {
      state.configTutorial = action.payload;
    },

    setStyleConfig(state, action) {
      state.payload.styleConfig[action.payload.config_id] =
        action.payload.value;
    },
    resetStyleConfig(state, action) {
      if (STYLE_SETTINGS[state.method]?.[state.payload.style || state.payload.contentStyle]) {
        state.payload.styleConfig = generate_style_config(
          state.method,
          state.payload.style || state.payload.contentStyle,
          action.payload?.use_current ? state.payload.styleConfig : {
						palette: state.payload.styleConfig?.palette,
						background: state.payload.styleConfig?.background,
						outline: state.payload.styleConfig?.outline_stroke,
					}
        );
      } else {
        state.payload.styleConfig = generate_style_config(
          state.method,
          state.payload.style || state.payload.contentStyle,
					{
						palette: state.payload.styleConfig?.palette,
						background: state.payload.styleConfig?.background,
						outline: state.payload.styleConfig?.outline_stroke,
					}
				);
      }
    },

    updateShowPromptTutorial(state, action) {
      state.showPromptTutorial = action.payload;
    },

    _switchDevMode(state, action) {
      state.__dev_mode = action.payload;

      if (!state.__dev_mode) {
        // state.payload = { ...state.payload, ...DEFAULT_STYLE_MAPPINGS[action.payload], };
        // for (const field of removeAfterDevMode) state.payload[field] = undefined;
        // state.payload.overridePromptInjection = false;
      }
    },

    updateQueueStatus(state, action) {
      state.queueStatus = action.payload;
    },

    generationStarted(state, action) {
      state.isLoading = true;
      state.showPlaceholders = true;
      state.generationStatus = GENERATION_STATUS.GENERATING;

      if (action.payload.runpod_payload) {
        state.__runpod_payload = action.payload.runpod_payload;
      }
    },
    generationFailed(state, action) {
      state.isLoading = false;
      state.showPlaceholders = true;
      state.generationStatus = GENERATION_STATUS.FAILED;

      if (action.payload.runpod_error) {
        state.__runpod_error = action.payload.runpod_error;
      }
    },
    generationSuccessful(state, action) {
      state.isLoading = false;
      state.showPlaceholders = false;

      state.generationStatus = GENERATION_STATUS.SUCCEDED;
      state.lastGenerationId = action.payload.generationId;
    },

    resetForRouteChange(state, action) {
      state.showPlaceholders = true;
    },

    selectActiveHistoryGeneration(state, action) {
      state.activeHistoryGeneration = action.payload;
    },

    partialGenerationStarted(state, action) {
      state.isLoading = true;
      state.showPlaceholders = false;
      state.generationStatus = GENERATION_STATUS.GENERATING;

      if (action.payload.runpod_payload) {
        state.__runpod_payload = action.payload.runpod_payload;
      }

			const inds = []

      state.generations = state.generations.map((item) => {
        if (
          item.generationId === action.payload.generation &&
          item.ind >= action.payload.index &&
          item.ind < action.payload.index + (action.payload.batch_size ?? 1)
        ) {
					inds.push(item.ind)
          return {
            ...item,
            status: GENERATION_STATUS.GENERATING,
						_startTime: item._startTime || Date.now(),
          };
        }
        return item;
      });
			const newItems = []
			for (let i = action.payload.index; i < action.payload.index + (action.payload.batch_size ?? 1); i++) {
				if (inds.includes(i)) continue;
				newItems.push({
					generationId: action.payload.generation,
					ind: i,
					status: GENERATION_STATUS.GENERATING,
					_startTime: Date.now(),
				})
			}
			state.generations = [...newItems, ...state.generations]    
		},
    partialGenerationFailed(state, action) {
      state.isLoading = false;
      state.showPlaceholders = false;
      state.generationStatus = GENERATION_STATUS.FAILED;

      if (action.payload.runpod_error) {
        state.__runpod_error = action.payload.runpod_error;
      }

      state.generations = state.generations.map((item) => {
        if (
          item.generationId === action.payload.generationId &&
          item.ind >= action.payload.index &&
          item.ind < action.payload.index + (action.payload.batch_size ?? 1)
        ) {
          return {
            ...item,
            status: GENERATION_STATUS.FAILED,
          };
        }
        return item;
      });
    },
    partialGenerationRemoveFailed(state, action) {
      state.generations = state.generations.filter((item) => {
        if (
          item.generationId === action.payload.generationId &&
          item.ind >= action.payload.index &&
          item.ind < action.payload.index + (action.payload.batch_size ?? 1)
        ) {
          return false;
        }
        return true;
      });
    },
    partialGenerationFinished(state, action) {
      state.isLoading = false;
      state.showPlaceholders = false;

      state.generationStatus = GENERATION_STATUS.SUCCEDED;
      state.lastGenerationId = action.payload.generationId;

      if (!state.__autogenerated_seed && state.__free_seed_after_generation) {
        state.__autogenerated_seed = true;
      }
    },

    resetLastGenerationId(state, action) {
      state.lastGenerationId = null;
    },
    updateLastGenerationId(state, action) {
      state.lastGenerationId = action.payload;
      if (action.payload) state.showPlaceholders = false;
    },

		updateImageStrength(state, action) {
			state.payload.imageStrength = action.payload
		},

		updateStyleImage(state, action) {
			if (action.payload) {
				if (state.method !== 'v4/style-transfer') {
					state.payload.__prev_method = state.method
					state.payload.__prev_settings = { ...state.payload }

					state.method = 'v4/style-transfer';
					state.payload = {
						...state.payload,
						...DEFAULT_SETTINGS_V4_STYLE_TRANSFER,
					};
				}
				state.payload.styleImage = action.payload;
				state.payload.styleImageUrl = undefined;

			} else {
				if (state.__prev_method && state.__prev_settings) {
					state.method = state.payload.__prev_method
					Object.entries(state.payload.__prev_settings).forEach(([key, val]) => {
						if (!(key in SHARED_SETTINGS)) {
							state.payload[key] = val;
						}
					})
					state.__prev_method = undefined;
					state.__prev_settings = undefined;
				} else {
					state.method = 'v4/waterfall';
					state.payload = {
						...state.payload,
						...DEFAULT_SETTINGS_V4_WATERFALL
					};
				}
				state.payload.styleImage = undefined;
				state.payload.styleImageUrl = undefined;
			}
		},

		updateStyleImageUrl(state, action) {
			if (action.payload) {
				if (state.method !== 'v4/style-transfer') {
					state.payload.__prev_method = state.method
					state.payload.__prev_settings = { ...state.payload }

					state.method = 'v4/style-transfer';
					state.payload = {
						...state.payload,
						...DEFAULT_SETTINGS_V4_STYLE_TRANSFER,
					};
				}
				state.payload.styleImage = undefined;
				state.payload.styleImageUrl = action.payload;

			} else {
				if (state.__prev_method && state.__prev_settings) {
					state.method = state.payload.__prev_method
					Object.entries(state.payload.__prev_settings).forEach(([key, val]) => {
						if (!(key in SHARED_SETTINGS)) {
							state.payload[key] = val;
						}
					})
					state.__prev_method = undefined;
					state.__prev_settings = undefined;
				} else {
					state.method = 'v4/waterfall';
					state.payload = {
						...state.payload,
						...DEFAULT_SETTINGS_V4_WATERFALL
					};
				}
				state.payload.styleImage = undefined;
				state.payload.styleImageUrl = undefined;
			}
		},

		addDevModeField(state, action) {
			if (state.devModeFields.indexOf(action.payload) > -1) return;

			state.devModeFields = [...state.devModeFields, action.payload]
		},
		removeDevModeField(state, action) {

			state.devModeFields = state.devModeFields.filter(x => x !== action.payload)
		},
  },
  extraReducers: (builder) => {
    builder.addCase(generateImages.pending, (state, action) => {
      state.isLoading = true;
      state.showPlaceholders = true;
      state.generationStatus = GENERATION_STATUS.GENERATING;
      state.showQueueForRequest = false;

      if (state.__autogenerated_seed) state.payload.seed = generateRandomSeed();

      state.payload.styleConfig = Object.entries(
        state.payload.styleConfig
      ).reduce((config, [id, item]) => {
        if (!item.__randomized) return { ...config, [id]: item };
        return {
          ...config,
          [id]: randomize_config_item(
            state.method,
            state.method.startsWith('v4') ? state.payload.style : state.payload.contentStyle,
            id,
            item
          ),
        };
      }, {});
    });
    builder.addCase(generateImages.fulfilled, (state, action) => {
      if (action.payload.runpod_payload) {
        state.__runpod_payload = action.payload.runpod_payload;
      }

      if (action.payload.isAsync) {
        state.queueStatus = action.payload.queue_status;
        if (action.payload.queue_status.show) {
          state.generationStatus = GENERATION_STATUS.IN_QUEUE;
          state.showQueueForRequest = true;
          state.showPlaceholders = true;
          state.isLoading = false;
        } else {
          state.generationStatus = GENERATION_STATUS.GENERATING;
          state.showQueueForRequest = false;
          state.showPlaceholders = true;
          state.isLoading = true;
        }
        if (action.payload.isPartial) {
          state.showPlaceholders = false;
          state.lastGenerationId = action.payload.generationId;
          state.partialGenerationCounter += 1;
          state.generations = [
            ...[...Array(action.payload.samples)]
              .map((_, ind) => ({
                generationId: action.payload.generationId,
                ind,
                status: GENERATION_STATUS.IN_QUEUE,
              }))
              .reverse().filter(x => !state.generations.find(y => y.generationId === x.generationId && y.ind === x.ind)),
            ...state.generations,
          ];
        }
      } else {
        state.generationStatus = GENERATION_STATUS.SUCCEDED;
        state.isLoading = false;
        state.showPlaceholders = false;

        state.lastGenerationId = action.payload.new_generations[0].generationId;

        state.generations = [
          ...action.payload.new_generations.reverse(),
          ...state.generations,
        ];

        if (!state.__autogenerated_seed && state.__free_seed_after_generation) {
          state.__autogenerated_seed = true;
        }
      }
    });
    builder.addCase(generateImages.rejected, (state, action) => {
      state.isLoading = false;
      state.showPlaceholders = true;
      state.generationStatus = GENERATION_STATUS.FAILED;
    });

    builder.addCase(upscaleImage.pending, (state, action) => {
      state.isUpscaling = true;
    });
    builder.addCase(upscaleImage.fulfilled, (state, action) => {
      if (action.payload.runpod_payload) {
        state.__runpod_payload = action.payload.runpod_payload;
      }
      state.isUpscaling = false;
    });
    builder.addCase(upscaleImage.rejected, (state, action) => {
      state.isUpscaling = false;
    });
    builder.addCase(upscaleImageTool.pending, (state, action) => {
      state.isUpscalingTool = true;
    });
    builder.addCase(upscaleImageTool.fulfilled, (state, action) => {
      state.afterUpscaleImage = action.payload.images[0];
      state.isUpscalingTool = false;
    });
    builder.addCase(upscaleImageTool.rejected, (state, action) => {
      state.isUpscalingTool = false;
    });

    builder.addCase(vectorizeImage.pending, (state, action) => {
      state.isVectorizing = true;
    });
    builder.addCase(vectorizeImage.fulfilled, (state, action) => {
      state.isVectorizing = false;
    });
    builder.addCase(vectorizeImage.rejected, (state, action) => {
      state.isVectorizing = false;
    });

    builder.addCase(vectorizeImageTool.pending, (state, action) => {
      state.isVectorizingTool = true;
    });
    builder.addCase(vectorizeImageTool.fulfilled, (state, action) => {
      state.afterVectorizeImage = action.payload.data.imageUrl;
      state.isVectorizingTool = false;
    });
    builder.addCase(vectorizeImageTool.rejected, (state, action) => {
      state.isVectorizingTool = false;
    });

    builder.addCase(bgRemove.pending, (state, action) => {
      state.isRemovingBg = true;
    });
    builder.addCase(bgRemove.fulfilled, (state, action) => {
      state.isRemovingBg = false;
    });
    builder.addCase(bgRemove.rejected, (state, action) => {
      state.isRemovingBg = false;
    });

    builder.addCase(bgRemoveTool.pending, (state, action) => {
      state.isRemovingBgTool = true;
    });
    builder.addCase(bgRemoveTool.fulfilled, (state, action) => {
      state.afterBgRemoveImage = action.payload.data.image;
      state.isRemovingBgTool = false;
    });
    builder.addCase(bgRemoveTool.rejected, (state, action) => {
      state.isRemovingBgTool = false;
    });

    builder.addCase(generateImageMasks.pending, (state, action) => {
      state.isGeneratingMasks = true;
      // state.generatedMasks = {};
    });
    builder.addCase(generateImageMasks.fulfilled, (state, action) => {
      state.isGeneratingMasks = false;
      state.generatedMasks = {
        ...state.generatedMasks,
        [action.payload.edgeThreshold]: action.payload.response.preprocessed,
      };
    });
    builder.addCase(generateImageMasks.rejected, (state, action) => {
      state.isGeneratingMasks = false;
    });

    builder.addCase(setImageAsInspiration.pending, (state, action) => {
      state.inspirationLoading = true;

      if (state.__autogenerated_seed) state.payload.seed = generateRandomSeed();
    });
    builder.addCase(setImageAsInspiration.fulfilled, (state, action) => {
      state.inspirationLoading = false;

      if (action.payload.method) {
        if (state.method !== action.payload.method) {
					state.payload = {
						...state.payload,
						...DEFAULT_STYLE_MAPPINGS[action.payload.method],
					};
        }

        state.method = action.payload.method;
      }

      state.generatedMasks = {};
      state.payload.inputImage = action.payload.image;
    });
    builder.addCase(setImageAsInspiration.rejected, (state, action) => {
      state.inspirationLoading = false;
    });

    builder.addCase(loadPartialGenerationResult.pending, (state, action) => {
      state.isLoadingGenerations = true;
    });
    builder.addCase(loadPartialGenerationResult.fulfilled, (state, action) => {
      state.isLoadingGenerations = false;

      if (
        state.generations.findIndex(
          (item) =>
            item.generationId === action.payload.generationId &&
            item.ind === action.payload.ind
        ) > -1
      ) {
        state.generations = state.generations.map((item) => {
          if (
            item.generationId === action.payload.generationId &&
            item.ind === action.payload.ind
          ) {
            return action.payload;
          }
          return item;
        });
      } else {
        state.generations = [action.payload, ...state.generations];
      }
    });
    builder.addCase(loadPartialGenerationResult.rejected, (state, action) => {
      state.isLoadingGenerations = false;
    });

    builder.addCase(loadGenerationById.pending, (state, action) => {
      state.isLoadingGenerations = true;
    });
    builder.addCase(loadGenerationById.fulfilled, (state, action) => {
      state.isLoadingGenerations = false;

      if (
        state.generations.findIndex(
          (item) => item.generationId === action.payload.generationId
        ) > -1
      ) {
        let last_id = 0;
        state.generations = state.generations.map((item, ind) => {
          if (item.generationId === action.payload.generationId) {
            return action.payload.docs[last_id++];
          }
          return item;
        });
				if (last_id < action.payload.docs.length) {
					state.generations = [action.docs.slice(last_id), ...state.generations].filter(
						(gen, ind, arr) =>
						!gen._id || arr.findIndex((g) => g._id === gen._id) === ind
					);
				}
      } else {
        state.generations = [...action.payload, ...state.generations].filter(
          (gen, ind, arr) =>
            !gen._id || arr.findIndex((g) => g._id === gen._id) === ind
        );
      }

      if (!state.__autogenerated_seed && state.__free_seed_after_generation) {
        state.__autogenerated_seed = true;
      }
    });
    builder.addCase(loadGenerationById.rejected, (state, action) => {
      state.isLoadingGenerations = false;
    });

    builder.addCase(loadGenerationHistory.pending, (state, action) => {
      state.isLoadingGenerations = true;
    });
    builder.addCase(loadGenerationHistory.fulfilled, (state, action) => {
      state.isLoadingGenerations = false;
      state.generations = [...state.generations, ...action.payload.docs].filter(
        (gen, ind, arr) =>
          !gen._id || arr.findIndex((g) => g._id === gen._id) === ind
      );
			state.activeHistoryGeneration = action.payload.docs[0]?._id
      state.hasMoreGenerationsToLoad =
        action.payload.totalDocs > state.generations.length;
    });
    builder.addCase(loadGenerationHistory.rejected, (state, action) => {
      state.isLoadingGenerations = false;
    });

    builder.addCase(bookmarkImage.pending, (state, action) => {});
    builder.addCase(bookmarkImage.fulfilled, (state, action) => {
      state.generations = state.generations.map((item) =>
        item._id === action.payload._id ? { ...item, ...action.payload } : item
      );
    });
    builder.addCase(bookmarkImage.rejected, (state, action) => {});

    builder.addCase(submitFeedback.pending, (state, action) => {});
    builder.addCase(submitFeedback.fulfilled, (state, action) => {
      state.generations = state.generations.map((item) =>
        item._id === action.payload._id ? { ...item, ...action.payload } : item
      );
    });
    builder.addCase(submitFeedback.rejected, (state, action) => {});

    builder.addCase(deleteImage.pending, (state, action) => {});
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      state.generations = state.generations.filter(
        (item) => item._id !== action.payload
      );
    });
    builder.addCase(deleteImage.rejected, (state, action) => {});

    builder.addCase(loadSettings.pending, (state, action) => {});
    builder.addCase(loadSettings.fulfilled, (state, action) => {
      state.method = action.payload.method ?? state.method;
      state.payload = {
        ...(state.method.startsWith('v4.0') ? {} : state.payload),
        ...action.payload.payload,
        promptTextHelper: {
          ...(action.payload.payload?.promptTextHelper ||
            state.payload.promptTextHelper),
        },
      };
      state.__force_update_prompt = state.payload.prompt;
      state.__autogenerated_seed = false;
      state.__free_seed_after_generation = true;
    });
    builder.addCase(loadSettings.rejected, (state, action) => {});
  },
});

export const {
  reset,
  updateAfterUpscaleImage,
  updateAfterBgRemoveImage,
  updateAfterVectorizeImage,
  updateImageAsControlImage,
  updateGuidenceStregth,
  updateImageWeight,
  updatePromptRigidness,
  partialUpdateStatePayload,
  updateEdgeThreshold,
  updatePrompt,
  updateNegativePrompt,
  updateMethod,
  updateRedesignMethod,
  updateImageQuality,
  updateDimensions,
  updateSampler,
  updateScheduler,
  updateImagesToGenerate,
  updateSeed,
  freezeSeed,
  unfreezeSeed,
  regenerateSeed,
  updateInputImage,
  updatePromptHelpers,
  appendPromptHelper,
  removePromptHelper,
  togglePromptHelper,
  updatePromptTextHelper,
  showImageModal,
  closeImageModal,
  clearPromptForceUpdate,
	updatePromptForceUpdate,
  updateShowOutOfCreditModal,
  updateOutOfCreditText,
  updateContentStyle,
  updateShouldInjectColros,
  updateInjectColorsAt,
  updateDetailLevel,
  updateShowStyleSelector,
  updateShowAdvancedSettingPopup,
  updateShowStyleSelectorTutorial,
  updateConfigTutorial,
  setStyleConfig,
  resetStyleConfig,
  updateShowPromptTutorial,
  _switchDevMode,
  updateQueueStatus,
  generationStarted,
  generationFailed,
  generationSuccessful,
  resetForRouteChange,
  selectActiveHistoryGeneration,
  partialGenerationFailed,
  partialGenerationRemoveFailed,
  partialGenerationStarted,
  partialGenerationFinished,
  resetLastGenerationId,
	updateLastGenerationId,
	updateImageStrength,
	updateStyleImage,
	updateStyleImageUrl,

	addDevModeField,
	removeDevModeField,
} = formSlice.actions;

export default formSlice.reducer;
