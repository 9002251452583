
import { useCallback, useEffect } from "react";
import { useStore, useSelector, } from "react-redux";
import authAxiosInstance from "./auth-axios";
import { generationFailed, generationStarted, generationSuccessful, loadGenerationById, loadPartialGenerationResult, partialGenerationFailed, partialGenerationFinished, partialGenerationRemoveFailed, partialGenerationStarted, selectActiveHistoryGeneration, updateQueueStatus } from "../reducers/formReducer";
import environment from './envProvider';
import { toast } from "react-toastify";
import { setUserTokens } from "../reducers/userReducer";

var socket = null;
var messages = [];
var monitoring = [];
var noReconnects = 0;
var ignore_history = true;

var isAlive = false;
var pingTimer = null;
var monitoringTimer = null;

var generation_time = {}

export const captureMonitoringEvent = (ev) => {
	monitoring.push({
		...ev,
		eid: ev.generationId ?? ev.id ??  `${Math.random().toString(36).substring(2, 15)}${Math.random().toString(36).substring(2, 15)}`
	})
}

export const recordGenerationStartTime = (generation_id, method_name) => {
	generation_time[generation_id] = {
		time: performance.now(),
		method: method_name,
		durationLimit: setTimeout(() => {
			generation_time[generation_id].durationLimit = null
			captureMonitoringEvent({
				name: 'generation-too-long',
				method: method_name,
			})
		}, 1000 * 100)
	}
}

export const recordGenerationTime = (generation_id) => {
	const gen_data = generation_time[generation_id]

	if (gen_data) {
		if (gen_data.durationLimit) {
			clearTimeout(gen_data.durationLimit)
		}
		captureMonitoringEvent({
			name: 'generation-finished',
			method: gen_data.method,
			duration: (performance.now() - gen_data.time) / 1000,
		})
		delete generation_time[generation_id]
	}
}


const SocketHandler = () => {
  const store = useStore();
  const user = useSelector((state) => state.userSlice.user);

	const handleMessage = useCallback(async (e) => {
		const data = JSON.parse(e.data)

		const processMessage = async (data) => {

			if (data.event === 'queue-update') {
				store.dispatch(updateQueueStatus(data.payload))
			}

			if (data.event === 'pong') {
				isAlive = true;
				return;
			}

			// Batch
			if (data.event === 'generation-ready') {
				recordGenerationTime(data.payload.generationId)
				await store.dispatch(loadGenerationById({
					generationId: data.payload.generationId
				})).unwrap()
				store.dispatch(generationSuccessful(data.payload))
				if (data.payload.user.remainingCredits)
					store.dispatch(setUserTokens({ tokens: data.payload.user.remainingCredits }))
				try {
					const notif = new Notification("Your generations are ready!")
				} catch(e) {
					console.log("Failed to create notification");
				}
			}

			if (data.event === 'generation-started') {
				store.dispatch(generationStarted(data.payload))
			}

			if (data.event === 'generation-failed') {
				store.dispatch(generationFailed(data.payload))
				captureMonitoringEvent({
					generationId: data.payload.generationId,
					name: 'generation-failed'
				})
				toast(data.payload?.message || 'Generation Failed. No credits were used.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					theme: 'dark',
					autoClose: 2000
				});
			}

			// Partial
			if (data.event === 'partial-generation-response') {
				data.payload.ids.forEach((id, ind) => {
					store.dispatch(loadPartialGenerationResult({
						ind: ind + data.payload.index,
						id
					}))
				})
				store.dispatch(selectActiveHistoryGeneration(data.payload.ids[data.payload.ids.length - 1]))
				try {
					const notif = new Notification("A new generation is ready!")
				} catch(e) {
					console.log("Failed to create notification");
				}
			}

			if (data.event === 'partial-generation-finished') {
				recordGenerationTime(data.payload.generationId)
				store.dispatch(partialGenerationFinished(data.payload))
				data.payload.ids.forEach((id, ind) => {
					store.dispatch(loadPartialGenerationResult({
						ind: ind + data.payload.index,
						id
					}))
				})
				store.dispatch(selectActiveHistoryGeneration(data.payload.ids[data.payload.ids.length - 1]))
				if (data.payload.user.remainingCredits)
					store.dispatch(setUserTokens({ tokens: data.payload.user.remainingCredits }))
				try {
					const notif = new Notification("Your generations are ready!")
				} catch(e) {
					console.log("Failed to create notification");
				}
			}

			if (data.event === 'partial-generation-started') {
				store.dispatch(partialGenerationStarted(data.payload))
			}

			if (data.event === 'partial-generation-failed') {
				captureMonitoringEvent({
					generationId: data.payload.generationId,
					name: 'generation-failed'
				})
				store.dispatch(partialGenerationFailed(data.payload))
				setTimeout(() => store.dispatch(partialGenerationRemoveFailed(data.payload)), 2000)
			}

			if (data.event === 'monitoring-ack') {
				monitoring = monitoring.filter(item => !data.payload.includes(item.eid))
			}
		}

		if (data.id) {
			messages.push(data.id)
			while (messages.length > 60) messages.shift()
		}

		if (data.event === 'recent-history') {

			for (const event of data.payload) {
				if (messages.indexOf(event.id) < 0) {
					messages.push(event.id)
					while (messages.length > 60) messages.shift()
					if (!ignore_history) processMessage(event);
				}
			}
			ignore_history = false;
		} else {
			processMessage(data)
		}

	}, [store])

	const connect = useCallback(async () => {
		if (!store.getState().userSlice?.user) return ;
		const { data } = await authAxiosInstance.post(
			'/auth/refresh-token', 
			{ 
				token: store.getState().userSlice?.refreshToken, 
				userId: store.getState().userSlice?.user._id 
			}
		)

		const url = `${
			environment('REACT_APP_API_URL') || "https://app.logodiffusion.com"
		}/ws/${store.getState().userSlice.user._id}?authorization=${
			data.accessToken}`.replace('https', 'wss')

		socket = new WebSocket(url)

		noReconnects++;

		const waitForConnection = (callback, interval) => {
			if (socket.readyState === 1) {
				callback()
			} else {
				setTimeout(() => waitForConnection(callback, interval), interval);
			}
		}

		const pingRunner = (interval) => {
			if (!isAlive) {
				socket.close()
				pingTimer = null;
				return ;
			}
			isAlive = false;

			socket.send(JSON.stringify({
				type: 'ping'
			}))

			pingTimer = setTimeout(() => pingRunner(interval), interval);
		}

		const monitoringHandler = (interval) => {
			try {
				if (monitoring.length) {
					socket.send(JSON.stringify(
						{
							type: 'monitoring',
							events: monitoring,
						}
					))
				}
			} catch(e) {
				console.log("Failed to send monitoring events", e)
			}

			monitoringTimer = setTimeout(() => monitoringHandler(interval), interval);
		}

		socket.addEventListener('open', () => {
			console.log("Sending message")
			waitForConnection(() => {
				isAlive = true;
				socket.send(JSON.stringify({
					type: 'load-recent'
				}))

				pingRunner(8000)
				// monitoringHandler(1000 * 60 * 2)
				monitoringHandler(1000 * 30)
				
			}, 300)
		})

		socket.addEventListener('close', () => {
			if (pingTimer) {
				clearTimeout(pingTimer);
				isAlive = false;
			}
			if (monitoringTimer) {
				clearTimeout(monitoringTimer);
			}
			setTimeout(() => {
				socket = null
				connect()
			}, 500)
		})
		socket.addEventListener('message', handleMessage)
	}, [store, handleMessage]);

	useEffect(() => {

		if (socket && !user) {
			socket.close()
			socket = null
		} else if (!socket && user) {
			connect()
		}

	}, [user, connect])


	return <></>
}

export default SocketHandler
